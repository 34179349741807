import React from 'react'
import './index.css'
import App from './App'
import {createRoot} from "react-dom/client"
import {BrowserRouter, Navigate} from "react-router-dom"
import {Routes, Route} from "react-router"

import {Chat} from "./pages/chat";
import {Account} from "./pages/account";
import {Live, CreateLiveRoom, JoinLiveRoom} from "./pages/live";

import 'mdui'
import 'mdui/mdui.css'
import {setTheme, setColorScheme} from 'mdui'

setColorScheme('#68548E')
setTheme("auto")

const root = createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <Routes>
            <Route element={<App/>}>
                {/*<Route path="/" element={<Chat/>}/>*/}
                <Route path="/" element={<Navigate to={"/live/"} replace={true}/>}/>
                <Route path={"/live"}>
                    <Route index element={<Live/>}/>
                    <Route path="CLR" element={<CreateLiveRoom/>}/>
                    <Route path=":anotherID" element={<JoinLiveRoom/>}/>
                </Route>
                {/*<Route path="/account" element={<Account/>}/>*/}
                <Route path="/account" element={<Navigate to={"/live/"} replace={true}/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
);