export async function genKey() {
    const keys = await window.crypto.subtle.generateKey(
        {
            name: "RSA-OAEP",
            modulusLength: 1024,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"],
    )
    return [keys.privateKey, keys.publicKey]
}

export async function getPublicKey(publicKeyJWK) {
    return await window.crypto.subtle.importKey("jwk", publicKeyJWK, {
        name: "RSA-OAEP",
        modulusLength: 512,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256",
    }, true, ["encrypt"])
}

export async function getPublicKeyJWK(publicKey) {
    return await window.crypto.subtle.exportKey(
        "jwk",
        publicKey
    )
}

export function ArrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    for (var len = bytes.byteLength, i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export function base64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function encrypt(string, publicKey) {
    return ArrayBufferToBase64(await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP",
        },
        publicKey,
        (new TextEncoder).encode(string),
    ))
}


export async function decrypt(data, privateKey) {
    return (new TextDecoder).decode(await window.crypto.subtle.decrypt(
        {name: "RSA-OAEP"},
        privateKey,
        base64ToUint8Array(data),
    ))
}