import {useState, useRef, useEffect, createContext, useContext, useMemo} from "react";
import {show_loading, loading_remove, Message, ChatPanel} from './components/components'
import {alert} from "mdui";
import {useNavigate, useParams} from "react-router-dom";
import {Peer} from "peerjs"
import {genKey, getPublicKey, encrypt, decrypt, getPublicKeyJWK} from "./components/CryptoUtils"

const CardContext = createContext()
const ConnContext = createContext()

function CreateChat() {
    const inputRef = useRef(null);
    const {setChatID, setMode, peerID} = useContext(CardContext)
    const [pin, setPin] = useState("")

    useEffect(() => {
        function handleToggle(e) {
            setPin(e.target.value)
        }

        inputRef.current.addEventListener('input', handleToggle);
    }, []);

    function createChat() {
        show_loading()
        fetch(`/api/createChat/?pin=${pin}&peerID=${peerID}`).then(res => res.text()).then(data => {
            setChatID(data)
            setMode("generate")
            loading_remove()
        })
    }

    return <>
        <span style={{marginBottom: "8px"}}>Set a PIN</span>
        <mdui-text-field label={"PIN"} type={"number"} ref={inputRef} value={pin}/>
        <mdui-button variant={"filled"} full-width onClick={() => createChat()}>Continue</mdui-button>
    </>
}

function JoinChat() {
    const PinInputRef = useRef(null);
    const ChatIDInputRef = useRef(null);
    const {setChatID, chatID} = useContext(CardContext)
    const [pin, setPin] = useState("")
    const navigate = useNavigate()

    useEffect(() => {

        PinInputRef.current.addEventListener('input', (e) => {
            setPin(e.target.value)
        })
        ChatIDInputRef.current.addEventListener('input', (e) => {
            setChatID(e.target.value)
        })
    }, []);

    function joinChat() {
        show_loading()
        fetch(`/api/checkPin/?chatID=${chatID}&pin=${pin}`).then(res => res.text()).then(data => {
            if (data === "false") {
                alert({
                    description: "Invalid Chat ID or PIN", confirmText: "OK"
                })
            } else {
                navigate(`/live/${data}`)
            }
            loading_remove()
        })
    }

    return <>
        <span style={{marginBottom: "8px"}}>Join a chat</span>
        <mdui-text-field label={"Chat ID"} ref={ChatIDInputRef} value={chatID}/>
        <mdui-text-field label={"PIN"} ref={PinInputRef} value={pin}/>
        <mdui-button variant={"filled"} full-width onClick={() => joinChat()}>Continue</mdui-button>
    </>
}


export function Live() {
    const [chatID, setChatID] = useState("")
    const navigate = useNavigate()

    return <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
    }}>
        <mdui-card variant={"elevated"} style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "16px",
            textAlign: "center",
            borderRadius: "26px"
        }}>
            <CardContext.Provider value={{chatID, setChatID}}>
                <JoinChat/>
            </CardContext.Provider>
            <mdui-button variant={"tonal"} onClick={() => navigate('/live/CLR/')} full-width>Create a chat</mdui-button>
        </mdui-card>
    </div>
}

export function CreateLiveRoom() {
    const [mode, setMode] = useState("create")
    const [peerID, setPeerID] = useState("undefined")
    const [chatID, setChatID] = useState("0")
    const [conn, setConn] = useState(null)
    const [messages, setMessages] = useState([])
    const [anotherPublicKey, setAnotherPublicKey] = useState("")
    const navigate = useNavigate()

    const recieveMessage = useMemo(() => {
        return (data) => {
            setMessages([...messages, data]);
        };
    }, [messages])

    useEffect(() => {
        (async () => {
            show_loading()
            const [privateKey, publicKey] = await genKey()
            const publicKeyJWK = await getPublicKeyJWK(publicKey)
            console.log(privateKey, publicKey, publicKeyJWK)
            const peer = new Peer()
            peer.on("open", (id) => {
                loading_remove()
                setPeerID(id)
            })
            peer.on("connection", (conn) => {
                window.conn = conn
                setConn(conn)
                conn.on("data", async (data) => {
                    console.log(data)
                    if (data.type === "EPK") {
                        console.log("EPK", data.data)
                        setAnotherPublicKey(await getPublicKey(data.data))
                        conn.send({type: "EPK", data: publicKeyJWK})
                        setMode("chat")
                    } else if (data.type === "QUIT") {
                        alert({
                            description: "The other one has left", confirmText: "OK", onConfirm: () => {
                                navigate("/live/")
                            }
                        })
                    } else if (data.type === "TEXT") {
                        // recieveMessage([1, await decrypt(data.data, privateKey)])
                        decrypt(data.data, privateKey).then(message => {
                            setMessages(prevMessages => [...prevMessages, [1, message]])
                        })
                    }
                })
            })
        })()
    }, [])

    function sendMessage(content) {
        (async () => {
            setMessages([...messages, [0, content]])
            conn.send({type: "TEXT", data: await encrypt(content, anotherPublicKey)})
        })()
    }

    return <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
    }}>
        <mdui-card variant={"elevated"} style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "16px",
            textAlign: "center",
            borderRadius: "26px"
        }}><CardContext.Provider value={{chatID, setChatID, mode, setMode, peerID}}>
            {mode === "create" && <CreateChat/>}
            {mode === "generate" && <>
                <span style={{marginBottom: "8px"}}>Waiting for someone to join</span>
                <span>Chat ID</span>
                <div style={{
                    display: "flex", justifyContent: "center", alignItems: "center", gap: "8px"
                }}>
                    <span>{chatID}</span>
                    <mdui-tooltip content="Copy">
                        <mdui-button-icon icon={"content_copy"} onClick={() => {
                            navigator.clipboard.writeText(chatID)
                        }}></mdui-button-icon>
                    </mdui-tooltip>
                </div>
                <span>This Chat ID will be deleted after 1 day</span>
            </>}
            {mode === "chat" && <ConnContext.Provider value={{conn, messages, sendMessage}}>
                <ChatView/>
            </ConnContext.Provider>}
        </CardContext.Provider></mdui-card>
    </div>
}

export function JoinLiveRoom() {
    const {anotherID} = useParams()
    const [conn, setConn] = useState(null)
    const [messages, setMessages] = useState([])
    const [anotherPublicKey, setAnotherPublicKey] = useState("")
    const navigate = useNavigate()

    const recieveMessage = useMemo(() => {
        return (data) => {
            setMessages([...messages, data]);
        };
    }, [messages])

    useEffect(() => {
        (async () => {
            show_loading()
            const [privateKey, publicKey] = await genKey()
            const publicKeyJWK = await getPublicKeyJWK(publicKey)
            console.log(privateKey, publicKey, publicKeyJWK)
            const peer = new Peer()
            peer.on("open", (id) => {
                const conn = peer.connect(anotherID)
                conn.on("open", () => {
                    window.conn = conn
                    setConn(conn)
                    conn.on("data", async (data) => {
                        console.log(data)
                        if (data.type === "EPK") {
                            console.log("EPK", data.data)
                            setAnotherPublicKey(await getPublicKey(data.data))
                            loading_remove()
                        } else if (data.type === "QUIT") {
                            alert({
                                description: "The other one has left", confirmText: "OK", onConfirm: () => {
                                    navigate("/live/")
                                }
                            })
                        } else if (data.type === "TEXT") {
                            // recieveMessage([1, await decrypt(data.data, privateKey)])
                            decrypt(data.data, privateKey).then(message => {
                                setMessages(prevMessages => [...prevMessages, [1, message]])
                            })
                        }
                    })
                    conn.send({type: "EPK", data: publicKeyJWK})
                })
            })
        })()
    }, [])

    function sendMessage(content) {
        (async () => {
            setMessages([...messages, [0, content]])
            conn.send({type: "TEXT", data: await encrypt(content, anotherPublicKey)})
        })()
    }

    return <ConnContext.Provider value={{conn, sendMessage, messages}}>
        <ChatView/>
    </ConnContext.Provider>
}


function ChatView() {
    const navigate = useNavigate()
    const {conn, messages, sendMessage} = useContext(ConnContext)
    console.log(messages)
    return <div style={{
        position: "fixed",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        zIndex: "9999",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "rgb(var(--mdui-color-background))"
    }}>
        <div style={{
            width: "90%",
            maxWidth: "620px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative"
        }}>
            <div style={{
                position: "absolute", top: "0", left: "0", width: "100%", padding: "8px", boxSizing: "border-box"
            }}>
                <mdui-top-app-bar style={{
                    position: "relative",
                    paddingInline: "12px",
                    borderRadius: "32px",
                    background: "transparent",
                    backdropFilter: "blur(12px)"
                }}
                                  scroll-behavior={"hide"}
                                  scroll-threshold={"60"}
                                  scroll-target={".messages-list"}>
                    <mdui-top-app-bar-title style={{textAlign: "left"}}>
                        Live Chat
                    </mdui-top-app-bar-title>
                    <mdui-button onClick={() => {
                        conn.send({type: "QUIT"});
                        navigate("/live/")
                    }}>Quit
                    </mdui-button>
                </mdui-top-app-bar>
            </div>
            <div className={"messages-list"}>
                {messages.map((data, index) => {
                    if (data[0] === 1) {
                        return <Message key={index} content={data[1]}/>
                    } else {
                        return <Message key={index} content={data[1]} right/>
                    }
                })}
            </div>
            <ChatPanel onSend={sendMessage}/>
        </div>
    </div>
}